import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RoutingError } from './util/error';

const pages = import.meta.glob<true, string, { default: React.ElementType }>('./pages/**/*.tsx', { eager: true });

const routes = { children: [] } as RouteObject;

Object.entries(pages).forEach(([path, page]) => {
  const Element = page.default;
  // Separate the path into parts
  const parts = path.split('/');
  const fileName = parts[parts.length - 1]!.replace('.tsx', '');
  const filePath = parts.slice(2, -1);
  let parent = routes;
  for (const part of filePath) {
    // Loop over the parts and create the route objects required for each part
    // For each part we check if the parent route already has a child with the same path
    // If it does we use that route as the parent for the next part and keep going
    // If it doesn't we create a new route object and add it to the parent's children array
    // and use that as the parent for the next part
    let newParent = parent.children!.find((route) => route.path === part);
    if (!newParent) {
      newParent = {
        path: part,
        children: [],
      };
      parent.children!.push(newParent);
    }
    parent = newParent;
  }
  // Once we have the parent route for the file we check if it's a layout or a page
  // If it's an error we set the errorElement to the error component with no path
  // If it's a layout we set the element to the layout component with no path
  // If it's a page we add the page to the children array of the parent route
  if (fileName === 'error') {
    parent.errorElement = <Element />;
  }
  if (fileName === 'layout') {
    parent.element = <Element />;
  }
  if (fileName === 'page') {
    parent.children!.push({
      path: '',
      element: <Element />,
    });
  }
});

// Finally we enable the use of layouts that do not affect the URL by removing the path
// if it starts with an underscore. We must do this last as we need a path to traverse
// the route tree and find the parent route for the layout in the previous step.
const cleanRoutes = (routes: RouteObject) => {
  if (routes.path?.startsWith('_')) {
    delete routes.path;
  }
  if (routes.children) {
    routes.children.forEach(cleanRoutes);
  }
};

cleanRoutes(routes);

// If no route is found we throw a SignerRoutingError which will be caught by the error boundary
routes.children!.push({
  path: '*',
  loader: () => {
    throw new RoutingError();
  },
});

export { routes };
