import { useEffect, useState } from 'react';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import jwt from 'jsonwebtoken';
import { Outlet, useLocation } from 'react-router-dom';
import ReactCodeInput from 'react-code-input';

import { configureEmbeddedSigner, getAuthenticationMethod, getEmbeddedSignerUser } from '@/state/repo';
import { useErrorRoute, ConfigError, SignerError, AuthenticationError } from '@/util/error';
import { AccountsInitiateEncryptionSessionCreateData, HttpResponse } from '@/api';
import { log } from '@/util/log';
import { Spinner } from '@/components/basic/spinner';

type AuthenticationMethod = AccountsInitiateEncryptionSessionCreateData['data']['authenticationMethod'];

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

type SignerProps = {
  idToken: string;
  accessToken: string;
  chainId: number;
  externalProviderUserId: string;
};

const validateQueryParams = (query: URLSearchParams): SignerProps => {
  const errors: string[] = [];
  const accessToken = query.get('access_token');
  const idToken = query.get('id_token');
  const externalProviderUserId = query.get('external_provider_user_id');
  const chainIdStr = query.get('chain_id');

  if (!idToken) errors.push('Missing idToken');
  if (!chainIdStr) {
    errors.push('Missing chainId');
  } else if (isNaN(Number(chainIdStr))) {
    errors.push('Invalid chainId');
  }

  if (errors.length > 0) {
    throw new ConfigError(errors);
  }

  return {
    idToken: idToken as string,
    accessToken: accessToken as string,
    externalProviderUserId: externalProviderUserId as string,
    chainId: Number(chainIdStr),
  };
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

enum SignerState {
  INIT,
  AUTOMATIC_SETTING_UP,
  AUTOMATIC_FAILED,
  SETTING_UP,
  FAILED,
  READY,
}

export default function Root() {
  const query = useQuery();
  const [params, setParams] = useState<SignerProps | null>(null);
  const [embeddedSignerState, setEmbeddedSignerState] = useState<SignerState>(SignerState.INIT);
  const [authenticationMethod, setAuthenticationMethod] = useState<AuthenticationMethod>();
  const [hint, setHint] = useState<string>();
  const throwError = useErrorRoute();

  useEffect(() => {
    // Attempt to automatically set up the embedded signer
    if (!params) return;
    const authenticateAndGetUser = async () => {
      if (embeddedSignerState !== SignerState.INIT) return;
      try {
        setEmbeddedSignerState(SignerState.AUTOMATIC_SETTING_UP);
        const { idToken, externalProviderUserId } = params;
        await getEmbeddedSignerUser({
          idToken,
          externalProviderUserId,
        });
        setEmbeddedSignerState(SignerState.READY);
        return;
      } catch (e) {
        log.info('Could not automatically recover user', e);
      }
      try {
        const { method, hint } = await getAuthenticationMethod(params.accessToken);
        setAuthenticationMethod(method);
        setHint(hint);
        setEmbeddedSignerState(SignerState.AUTOMATIC_FAILED);
        return;
      } catch (e) {
        log.error('Failed to get authentication method:', e);
        if (e instanceof Response) {
          const message = (e as HttpResponse<AccountsInitiateEncryptionSessionCreateData, { message: string; ok: boolean }>).error.message;
          throwError(new SignerError([message]));
        } else if (e instanceof Error) {
          throwError(new SignerError([e.message]));
        }
        throwError(new SignerError(['Failed to get authentication method']));
      }
    };
    void authenticateAndGetUser();
  }, [params, embeddedSignerState]);

  const handlePin = async (pin: string) => {
    // Recover the embedded signer using the recovery password
    if (authenticationMethod && params && pin.length === 6) {
      setEmbeddedSignerState(SignerState.SETTING_UP);
      try {
        await configureEmbeddedSigner(params.idToken, params.accessToken, params.chainId, params.externalProviderUserId, authenticationMethod, pin);
        setEmbeddedSignerState(SignerState.READY);
      } catch (e) {
        log.error('Error configuring embedded signer', e);
        setEmbeddedSignerState(SignerState.FAILED);
        if (e instanceof Error) {
          const message = e.message.toLowerCase();
          if (message.includes('wrong recovery password')) {
            throwError(new AuthenticationError());
          } else {
            throwError(new SignerError(['Failed to get authentication method']));
          }
        } else {
          throwError(new SignerError(['Failed to get authentication method']));
        }
      }
    }
  };

  if (!params) {
    setParams(validateQueryParams(query));
  }

  if (params) {
    log.info(jwt.decode(params.accessToken));
  }

  const pinText: Record<AuthenticationMethod, string> = {
    EMAIL_OTP: 'Enter your one time password',
    USER_PASSWORD: 'Enter your recovery password',
  };

  return (
    <div className="flex min-w-[320px] max-w-[460px] flex-col items-center justify-center gap-5">
      {embeddedSignerState === SignerState.INIT && (
        <>
          <img src="/icons/portal-logo.svg" width="32px" height="36px" alt="Portal logo" />
          <p>Initialising</p>
          <Spinner />
        </>
      )}
      {embeddedSignerState === SignerState.AUTOMATIC_SETTING_UP && (
        <>
          <img src="/icons/portal-logo.svg" width="32px" height="36px" alt="Portal logo" />
          <p>Retrieving details</p>
          <Spinner />
        </>
      )}
      {(embeddedSignerState === SignerState.AUTOMATIC_FAILED || embeddedSignerState === SignerState.SETTING_UP) && (
        <>
          <img src="/icons/portal-logo.svg" width="32px" height="36px" alt="Portal logo" />
          <p className="text-2xl">{pinText[authenticationMethod as AuthenticationMethod] || 'Enter your Recovery Password'}</p>
          {hint ? <p className="text-center">{hint}</p> : null}

          <div className="mb-[16px] flex gap-[14px]">
            <ReactCodeInput
              inputStyle={{
                margin: '8px',
                fontSize: '44px',
                width: '50px',
                height: '60px',
                paddingTop: '5px',
                textAlign: 'center',
                borderRadius: '8px',
                border: '1px solid #D9D9D9',
              }}
              disabled={embeddedSignerState === SignerState.SETTING_UP}
              inputMode="numeric"
              type="text"
              name="token"
              fields={6}
              onChange={(value) => {
                if (value.length === 6) {
                  // blur input
                  const focusElement = document.activeElement as HTMLElement;
                  focusElement?.blur();
                  void handlePin(value);
                }
              }}
            />
          </div>
        </>
      )}
      {embeddedSignerState === SignerState.READY && <Outlet />}
    </div>
  );
}
