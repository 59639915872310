import { useState } from 'react';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { TypedDataField } from '@openfort/openfort-js';
import { useLocation } from 'react-router-dom';

import { openfort } from '@/state/repo';
import { useErrorRoute, ConfigError, SignerError } from '@/util/error';
import { postSuccess } from '@/util/post-message';
import { log } from '@/util/log';
import { Button } from '@/components/basic/button';

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

type TypedDataPayload = {
  types: {
    EIP712Domain: Array<{ name: string; type: string }>;
    [key: string]: Array<{ name: string; type: string }>;
  };
  domain: {
    name?: string;
    version?: string;
    verifyingContract?: string;
    salt?: string;
  };
  primaryType: string;
  message: Record<string, unknown>;
};

type SignerProps = {
  payload: TypedDataPayload | string;
  hashify?: boolean;
  arrayify?: boolean;
};

const validateQueryParams = (query: URLSearchParams): SignerProps => {
  const errors: string[] = [];
  const payloadStr = query.get('payload');
  const arrayify = query.get('arrayify') === 'true';
  const hashify = query.get('hashify') !== 'false';

  let payload: TypedDataPayload | string;
  if (!payloadStr) {
    errors.push('Missing payload');
  } else {
    try {
      payload = JSON.parse(payloadStr) as TypedDataPayload | string;
    } catch (e) {
      errors.push('Invalid payload');
    }
  }

  if (errors.length > 0) {
    throw new ConfigError(errors);
  }

  return {
    payload: payload!,
    arrayify,
    hashify,
  };
};

const typedDataGuard = (payload: TypedDataPayload | string): payload is TypedDataPayload => {
  return (
    Object.prototype.hasOwnProperty.call(payload, 'types') &&
    Object.prototype.hasOwnProperty.call(payload, 'domain') &&
    Object.prototype.hasOwnProperty.call(payload, 'primaryType') &&
    Object.prototype.hasOwnProperty.call(payload, 'message')
  );
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Sign() {
  const [params, setParams] = useState<SignerProps | null>(null);
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const throwError = useErrorRoute();

  if (!params) {
    setParams(validateQueryParams(query));
  }

  const signHandler = async () => {
    if (!params) return;
    setLoading(true);
    const { payload, hashify, arrayify } = params;

    if (!typedDataGuard(payload)) {
      try {
        const signature = await openfort.signMessage(payload, { hashMessage: hashify, arrayifyMessage: arrayify });
        postSuccess({ data: signature });
        setLoading(false);
      } catch (e) {
        log.error('Error signing', e);
        setLoading(false);
        throwError(new SignerError());
      }
      return;
    }

    const types: Record<string, Array<TypedDataField>> = payload.types;
    delete types.EIP712Domain; // TODO: work out why this is necessary - it is added to our TypedDataPayload by Wagmi but is empty and invalid when empty

    try {
      const signature = await openfort.signTypedData(payload.domain, types, payload.message);
      postSuccess({ data: signature });
    } catch (e) {
      log.error('Error signing', e);
      throwError(new SignerError());
    }
  };

  let data = '';
  if (params?.payload) {
    data = params?.payload && !typedDataGuard(params?.payload) ? params.payload : JSON.stringify(params?.payload, null, 2);
  }

  return (
    <>
      <h1 className="flex flex-col items-center gap-5">
        <img src="/icons/portal-logo.svg" width="32px" height="36px" alt="Portal logo" />
        <p className="mb-[26px] text-2xl">Sign Message</p>
      </h1>
      <p>You are about to sign a message with the following payload. Please review the payload before signing.</p>
      <div className="mx-[16px] mb-[16px] flex max-h-[300px] max-w-full overflow-auto rounded-lg bg-gray-30 p-2">
        <pre className="whitespace-pre-line">{data}</pre>
      </div>
      <Button onClick={signHandler} type="button" loading={loading} disabled={loading}>
        Sign
      </Button>
    </>
  );
}
