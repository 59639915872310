import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/util/class-name-merge';
import { Spinner } from '@/components/basic/spinner';

const buttonStyles = cva(
  cn(
    'inline-flex flex-initial cursor-pointer items-center gap-2 whitespace-nowrap rounded-full text-base font-normal',
    'disabled:cursor-not-allowed disabled:opacity-50',
    'hover:brightness-90',
  ),
  {
    variants: {
      variant: {
        primary: 'bg-black text-white',
        secondary: 'bg-gray-20 text-black',
        error: 'bg-red text-white hover:bg-red/90',
        success: 'bg-green text-white',
      },
      size: {
        large: 'h-[48px] w-full px-[20px]',
        medium: 'h-[40px] w-full px-[16px]',
        small: 'h-[28px] w-full px-[16px] text-sm',
      },
      icon: {
        true: 'justify-between',
        false: 'justify-center',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'medium',
    },
  },
);

const iconStyles = cva('shrink-0 rounded-full', {
  variants: {
    variant: {
      primary: 'bg-white text-black',
      secondary: 'bg-black text-white',
      error: 'bg-white text-white',
      success: 'bg-white text-white',
    },
    size: {
      small: 'size-[20px] p-[2px]',
      medium: 'size-[30px] p-[6px]',
      large: 'size-[24px]',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'medium',
  },
});

const loadingStyles = cva('', {
  variants: {
    variant: {
      primary: 'bg-transparent text-white',
      secondary: 'bg-transparent text-black',
      error: 'bg-transparent text-white',
      success: 'bg-transparent text-white',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonStyles> {
  animate?: boolean;
  loading?: boolean;
  Icon?: React.ElementType;
  iconLast?: boolean;
  iconClassName?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, type, variant, size, children, animate, disabled, loading, onClick, Icon, iconLast, iconClassName, ...rest }, forwardRef) => {
    // Handle animation for icon and span
    const ref = React.useRef<HTMLButtonElement | null>(null);
    const spanRef = React.useRef<HTMLSpanElement | null>(null);
    const [hover, setHover] = React.useState(false);
    let transformIconX = 0;
    let transformSpanX = 0;

    if (hover && Icon) {
      const iconWidth = {
        icon: 0,
        small: 20,
        medium: 30,
        large: 24,
      }[size || 'medium'];
      const spanPadding = {
        small: 16,
        medium: 16,
        large: 20,
        icon: 0,
      }[size || 'medium'];
      const iconPadding = 4;
      const gapWidth = 8;
      const buttonWidth = ref.current?.getBoundingClientRect().width || 0;
      const spanWidth = spanRef.current?.getBoundingClientRect().width || 0;
      const remainingWidth = buttonWidth - spanPadding - gapWidth - iconWidth - iconPadding;
      const direction = iconLast ? -1 : 1;
      transformIconX = direction * (gapWidth + remainingWidth + spanPadding - iconPadding);
      transformSpanX = -1 * direction * (remainingWidth - spanWidth + gapWidth + iconWidth + iconPadding - spanPadding);
    }

    const LoadingSpinner = React.useCallback(
      ({ className, ...props }: React.ComponentPropsWithoutRef<'span'>) => (
        <span className={cn(className, 'flex items-center justify-center')} {...props}>
          <Spinner />
        </span>
      ),
      [],
    );

    const ActualIcon = loading ? LoadingSpinner : Icon;
    const renderActualIcon = () =>
      ActualIcon && (
        <ActualIcon
          className={cn(iconStyles({ variant, size }), loading && loadingStyles({ variant }), iconClassName)}
          style={{
            transform: hover ? `translateX(${transformIconX}px) rotate(360deg)` : 'translateX(0) rotate(0deg)',
            transition: 'transform 0.3s',
          }}
        />
      );

    return (
      <button
        onMouseEnter={() => setHover(!!animate)}
        onMouseLeave={() => setHover(false)}
        onClick={onClick}
        disabled={disabled}
        type={type === 'button' ? 'button' : 'submit'}
        className={cn(buttonStyles({ variant, size, className, icon: !!Icon }), Icon && !iconLast && 'pl-1', Icon && iconLast && 'pr-1')}
        ref={(node) => {
          ref.current = node;
          if (typeof forwardRef === 'function') {
            forwardRef(node);
          } else if (forwardRef) {
            forwardRef.current = node;
          }
        }}
        {...rest}
      >
        {!iconLast && renderActualIcon()}
        <span
          ref={spanRef}
          style={{
            lineHeight: 0,
            transform: `translateX(${transformSpanX}px)`,
            transition: 'transform 0.3s',
          }}
        >
          {children}
        </span>
        {iconLast && renderActualIcon()}
      </button>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonStyles };
