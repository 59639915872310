/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface NotificationsListParams {
  seen?: boolean;
  /** @default 10 */
  limit?: number;
  /** @default 0 */
  offset?: number;
}

export interface NotificationsListData {
  data: {
    id: string;
    content: string;
    subcontent?: string;
    category?: string;
    url?: string;
    icon?: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    seen: boolean;
  }[];
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export type NotificationsSeenUpdateData = any;

export interface HealthzListData {
  message: string;
}

export interface AccountsPlayerCreatePayload {
  onboardingToken: string;
}

export interface AccountsPlayerCreateData {
  data: {
    player: {
      externalId: string;
      walletAddress: string;
      signerConfigured: boolean;
    };
    encryptionSessions: string[];
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface AccountsCreatePayload {
  onboardingToken: string;
}

export interface AccountsCreateData {
  data: {
    account: {
      id: string;
      userId: string;
      externalId: string;
      walletAddress: string;
      /**
       * @minLength 4
       * @maxLength 12
       */
      gamertag: string;
      authenticationMethod: "EMAIL_OTP" | "USER_PASSWORD";
      profilePictureUrl: string;
      /** @format date-time */
      createdAt: string;
      /** @format date-time */
      updatedAt: string;
    };
    continueUri: string;
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface AccountsFriendsListParams {
  gamerTag?: string;
  walletAddress?: string;
  /** @default 10 */
  limit?: number;
  /** @default 0 */
  offset?: number;
}

export interface AccountsFriendsListData {
  data: {
    id: string;
    userId: string;
    walletAddress: string;
    profilePictureUrl: string;
    /**
     * @minLength 4
     * @maxLength 12
     */
    gamertag: string;
    /** @format date-time */
    createdAt: string;
  }[];
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface AccountsFriendsPartialUpdatePayload {
  userId: string;
}

export interface AccountsFriendsPartialUpdateData {
  data: {
    id: string;
    userId: string;
    walletAddress: string;
    profilePictureUrl: string;
    /**
     * @minLength 4
     * @maxLength 12
     */
    gamertag: string;
    /** @format date-time */
    createdAt: string;
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export type AccountsFriendsDeleteData = any;

export interface AccountsMePartialUpdatePayload {
  /**
   * @minLength 4
   * @maxLength 12
   */
  gamertag?: string;
  /** @format uri */
  profilePictureUrl?: string;
}

export interface AccountsMePartialUpdateData {
  data: {
    id: string;
    userId: string;
    externalId: string;
    walletAddress: string;
    /**
     * @minLength 4
     * @maxLength 12
     */
    gamertag: string;
    authenticationMethod: "EMAIL_OTP" | "USER_PASSWORD";
    profilePictureUrl: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export type AccountsMePartialUpdateError =
  | {
      statusCode: 400;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 401;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 403;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 404;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 429;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    };

export interface AccountsGamertagListParams {
  /**
   * @minLength 4
   * @maxLength 12
   */
  gamertag: string;
}

export interface AccountsGamertagListData {
  data: {
    available: boolean;
    suggestions?: string[];
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface AccountsInitiateEncryptionSessionCreateData {
  data: {
    authenticationMethod: "EMAIL_OTP" | "USER_PASSWORD";
    userHint?: string;
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface AccountsEncryptionSessionCreatePayload {
  code: string;
}

export interface AccountsEncryptionSessionCreateData {
  data: {
    encryptionSession: string;
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface AccountsSessionKeyCreatePayload {
  chainId: number;
  policyId?: string;
  contractsWhitelisted?: string[];
  limit?: number;
}

export interface AccountsSessionKeyCreateData {
  data: {
    responseId: string;
    requiresNextAction: boolean;
    userOperationHash?: string;
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface AccountsSessionKeyListParams {
  chainId: number;
}

export interface AccountsSessionKeyListData {
  data: {
    walletAddress: string;
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface TransactionsCreatePayload {
  chainId: number;
  interaction: {
    to?: string;
    value?: string;
    contract?: string;
    functionName?: string;
    functionArgs?: string[];
    dataSuffix?: string;
    data?: string;
  };
  policyId?: string;
  externalOwnerAddress?: string;
}

export interface TransactionsCreateData {
  data: {
    responseId: string;
    requiresNextAction: boolean;
    userOperationHash?: string;
    transactionHash?: string;
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface TransactionsListParams {
  chain?: "eth" | "arbitrum";
  type?: "nft" | "erc20" | "native";
  fromBlock?: number;
  sort?: (any | string) & {
    field?: "block_number";
    order?: "asc" | "desc";
  };
  /** @default 10 */
  limit?: number;
  /** @default 0 */
  offset?: number;
}

export interface TransactionsListData {
  data: {
    fromAccount?: {
      id: string;
      userId: string;
      externalId: string;
      walletAddress: string;
      /**
       * @minLength 4
       * @maxLength 12
       */
      gamertag: string;
      authenticationMethod: "EMAIL_OTP" | "USER_PASSWORD";
      profilePictureUrl: string;
      /** @format date-time */
      createdAt: string;
      /** @format date-time */
      updatedAt: string;
    } | null;
    toAccount?: {
      id: string;
      userId: string;
      externalId: string;
      walletAddress: string;
      /**
       * @minLength 4
       * @maxLength 12
       */
      gamertag: string;
      authenticationMethod: "EMAIL_OTP" | "USER_PASSWORD";
      profilePictureUrl: string;
      /** @format date-time */
      createdAt: string;
      /** @format date-time */
      updatedAt: string;
    } | null;
    /** @format date-time */
    transactionDate: string;
    category: string;
    methodName?: string | null;
    value?: string | null;
    tokenSymbol?: string | null;
    fromAddress?: string | null;
    toAddress?: string | null;
    summary: string;
    hash: string;
    explorerLink: string;
  }[];
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface TransactionsIntentCreatePayload {
  chainId: number;
  interaction: {
    to?: string;
    value?: string;
    contract?: string;
    functionName?: string;
    functionArgs?: string[];
    dataSuffix?: string;
    data?: string;
  };
  policyId?: string;
  externalOwnerAddress?: string;
}

export interface TransactionsIntentCreateData {
  data: {
    responseId: string;
    requiresNextAction: boolean;
    userOperationHash?: string;
    transactionHash?: string;
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface TransactionsConfirmCreatePayload {
  id: string;
  signature: string;
}

export interface TransactionsConfirmCreateData {
  data: {
    responseId: string;
    requiresNextAction: boolean;
    userOperationHash?: string;
    transactionHash?: string;
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export interface NftsListParams {
  chain?: "eth" | "arbitrum";
  sort?: (any | string) & {
    field?: "block_number" | "name";
    order?: "asc" | "desc";
  };
  /** @default 10 */
  limit?: number;
  /** @default 0 */
  offset?: number;
}

export interface NftsListData {
  data: {
    ownerAddress: string;
    nftId: number;
    tokenAddress: string;
    chain: string;
    /** @format date-time */
    timestamp: string;
    blockNumber: number;
    metadata?: {
      name?: string | null;
      description?: string | null;
      image?: string | null;
      attributes?: {
        trait_type: string;
        value: string;
      }[];
    };
  }[];
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export type NftsListError =
  | {
      statusCode: 401;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 403;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    };

export type ImagesCreatePayload = any;

export interface ImagesCreateData {
  data: {
    /** @format uri */
    imageUrl: string;
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export type ImagesCreateError =
  | {
      statusCode: 413;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 415;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    };

export interface AppsListParams {
  /** @default 10 */
  limit?: number;
  /** @default 0 */
  offset?: number;
}

export interface AppsListData {
  data: {
    /** @format uuid */
    id: string;
    /** @minLength 1 */
    name: string;
    /** @minLength 1 */
    clientId: string;
    /** @minLength 1 */
    ownerId: string;
    /** @minLength 1 */
    clientSecret: string;
    logoUri?: string;
    callbacks: string[];
    grantTypes: string[];
    clientType: "public" | "private";
    responseType: "public" | "private";
  }[];
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export type AppsListError =
  | {
      statusCode: 401;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 403;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    };

export interface AppsCreatePayload {
  /** @minLength 1 */
  name: string;
  logoUri?: string;
  callbacks: string[];
  grantTypes: string[];
  clientType: "public" | "private";
  responseType: "public" | "private";
  /** @default 10 */
  limit?: number;
  /** @default 0 */
  offset?: number;
}

export interface AppsCreateData {
  data: {
    /** @format uuid */
    id: string;
    /** @minLength 1 */
    name: string;
    /** @minLength 1 */
    clientId: string;
    /** @minLength 1 */
    ownerId: string;
    /** @minLength 1 */
    clientSecret: string;
    logoUri?: string;
    callbacks: string[];
    grantTypes: string[];
    clientType: "public" | "private";
    responseType: "public" | "private";
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export type AppsCreateError =
  | {
      statusCode: 401;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 403;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    };

export interface AppsDetailData {
  data: {
    /** @format uuid */
    id: string;
    /** @minLength 1 */
    name: string;
    /** @minLength 1 */
    clientId: string;
    /** @minLength 1 */
    ownerId: string;
    /** @minLength 1 */
    clientSecret: string;
    logoUri?: string;
    callbacks: string[];
    grantTypes: string[];
    clientType: "public" | "private";
    responseType: "public" | "private";
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export type AppsDetailError =
  | {
      statusCode: 401;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 403;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 404;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    };

export interface AppsPartialUpdatePayload {
  /** @minLength 1 */
  name?: string;
  logoUri?: string;
  callbacks?: string[];
  grantTypes?: string[];
  clientType?: "public" | "private";
  responseType?: "public" | "private";
  /** @default 10 */
  limit?: number;
  /** @default 0 */
  offset?: number;
}

export interface AppsPartialUpdateData {
  data: {
    /** @format uuid */
    id: string;
    /** @minLength 1 */
    name: string;
    /** @minLength 1 */
    clientId: string;
    /** @minLength 1 */
    ownerId: string;
    /** @minLength 1 */
    clientSecret: string;
    logoUri?: string;
    callbacks: string[];
    grantTypes: string[];
    clientType: "public" | "private";
    responseType: "public" | "private";
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export type AppsPartialUpdateError =
  | {
      statusCode: 401;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 403;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 404;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    };

export type AppsDeleteData = object | null;

export type AppsDeleteError =
  | {
      statusCode: 401;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 403;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 404;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    };

export interface AppsCredentialsCreateData {
  data: {
    /** @format uuid */
    id: string;
    /** @minLength 1 */
    name: string;
    /** @minLength 1 */
    clientId: string;
    /** @minLength 1 */
    ownerId: string;
    /** @minLength 1 */
    clientSecret: string;
    logoUri?: string;
    callbacks: string[];
    grantTypes: string[];
    clientType: "public" | "private";
    responseType: "public" | "private";
  };
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export type AppsCredentialsCreateError =
  | {
      statusCode: 401;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 403;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    };

export interface GamesListParams {
  featured?: string;
  new?: string;
  genres?: string;
  platforms?: string;
  sort?: (any | string) & {
    field?: "created_at" | "name";
    order?: "asc" | "desc";
  };
  /** @default 10 */
  limit?: number;
  /** @default 0 */
  offset?: number;
}

export interface GamesListData {
  data: {
    id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    title: string;
    name: string;
    rating?: "EVERYONE" | "EVERYONE_10_PLUS" | "TEEN" | "MATURE" | "ADULTS_ONLY" | null;
    published: boolean;
    description?: string | null;
    extendedDescription?: string | null;
    websiteURL?: string | null;
    developer?: string | null;
    publisher?: string | null;
    featuredSplashURL?: string | null;
    trendingSplashURL?: string | null;
    trendingSpriteURL?: string | null;
    logoURL?: string | null;
    miniatureURL?: string | null;
    boxArtURL?: string | null;
    gameTrailerURL?: string | null;
    steamURL?: string | null;
    twitterURL?: string | null;
    /** @default false */
    isPortalGame?: boolean;
    /** @default false */
    featured?: boolean;
    categories: (
      | "TurnBased"
      | "Strategy"
      | "Adventure"
      | "RPG"
      | "MMORPG"
      | "TCG"
      | "MOBA"
      | "ActionRPG"
      | "Action"
      | "AutoBattler"
      | "BattleRoyale"
      | "Horror"
      | "Puzzle"
      | "Casual"
      | "Hypercasual"
      | "Sports"
      | "Racing"
      | "Fighting"
      | "Shooter"
      | "Simulation"
      | "Social"
      | "Survival"
      | "Lifestyle"
      | "BaseBuilder"
      | "BuildAndBattle"
    )[];
    platforms: ("PC" | "XBOX" | "PLAYSTATION" | "IOS" | "ANDROID")[];
    media?: any;
    releaseDate?: number | string | null;
  }[];
  meta: {
    total: number;
    offset: number;
    limit: number;
  } | null;
  /** @default 200 */
  status?: number;
  error: string | null;
}

export type GamesListError =
  | {
      statusCode: 401;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    }
  | {
      statusCode: 403;
      error: string | null;
      details?:
        | {
            field: string;
            message: string;
          }[]
        | null;
    };

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://api.portalgaming.com";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Portal Platform API
 * @version 1.0.0
 * @baseUrl https://api.portalgaming.com
 *
 * API for the Portal Platform
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  v1 = {
    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsList
     * @request GET:/v1/notifications/
     * @response `200` `NotificationsListData` Default Response
     */
    notificationsList: (query: NotificationsListParams, params: RequestParams = {}) =>
      this.request<NotificationsListData, any>({
        path: `/v1/notifications/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsSeenUpdate
     * @request PUT:/v1/notifications/{notificationId}/seen
     * @response `201` `NotificationsSeenUpdateData` Default Response
     */
    notificationsSeenUpdate: (notificationId: string, params: RequestParams = {}) =>
      this.request<NotificationsSeenUpdateData, any>({
        path: `/v1/notifications/${notificationId}/seen`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Health
     * @name HealthzList
     * @request GET:/v1/healthz
     * @response `200` `HealthzListData` Default Response
     */
    healthzList: (params: RequestParams = {}) =>
      this.request<HealthzListData, any>({
        path: `/v1/healthz`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Create an Openfort smart account for the user
     *
     * @tags Account
     * @name AccountsPlayerCreate
     * @request POST:/v1/accounts/player
     * @response `201` `AccountsPlayerCreateData` Default Response
     */
    accountsPlayerCreate: (data: AccountsPlayerCreatePayload, params: RequestParams = {}) =>
      this.request<AccountsPlayerCreateData, any>({
        path: `/v1/accounts/player`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a Portal account for the user
     *
     * @tags Account
     * @name AccountsCreate
     * @request POST:/v1/accounts/
     * @response `201` `AccountsCreateData` Default Response
     */
    accountsCreate: (data: AccountsCreatePayload, params: RequestParams = {}) =>
      this.request<AccountsCreateData, any>({
        path: `/v1/accounts/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get user friends or search for new friends
     *
     * @tags Account
     * @name AccountsFriendsList
     * @request GET:/v1/accounts/friends
     * @response `200` `AccountsFriendsListData` Default Response
     */
    accountsFriendsList: (query: AccountsFriendsListParams, params: RequestParams = {}) =>
      this.request<AccountsFriendsListData, any>({
        path: `/v1/accounts/friends`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Add a friend
     *
     * @tags Account
     * @name AccountsFriendsPartialUpdate
     * @request PATCH:/v1/accounts/friends
     * @response `200` `AccountsFriendsPartialUpdateData` Default Response
     */
    accountsFriendsPartialUpdate: (data: AccountsFriendsPartialUpdatePayload, params: RequestParams = {}) =>
      this.request<AccountsFriendsPartialUpdateData, any>({
        path: `/v1/accounts/friends`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Remove a friend
     *
     * @tags Account
     * @name AccountsFriendsDelete
     * @request DELETE:/v1/accounts/friends/{userId}
     * @response `200` `AccountsFriendsDeleteData` Default Response
     */
    accountsFriendsDelete: (userId: string, params: RequestParams = {}) =>
      this.request<AccountsFriendsDeleteData, any>({
        path: `/v1/accounts/friends/${userId}`,
        method: "DELETE",
        ...params,
      }),

    /**
 * @description Partially update an account object
 *
 * @tags Account
 * @name AccountsMePartialUpdate
 * @request PATCH:/v1/accounts/me
 * @response `200` `AccountsMePartialUpdateData` Default Response
 * @response `400` `{
    statusCode: 400,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `401` `{
    statusCode: 401,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `403` `{
    statusCode: 403,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `404` `{
    statusCode: 404,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `429` `{
    statusCode: 429,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 */
    accountsMePartialUpdate: (data: AccountsMePartialUpdatePayload, params: RequestParams = {}) =>
      this.request<AccountsMePartialUpdateData, AccountsMePartialUpdateError>({
        path: `/v1/accounts/me`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Search for gamer tag availablity, if not available returns an array of suggestions
     *
     * @tags Account
     * @name AccountsGamertagList
     * @request GET:/v1/accounts/gamertag
     * @response `200` `AccountsGamertagListData` Default Response
     */
    accountsGamertagList: (query: AccountsGamertagListParams, params: RequestParams = {}) =>
      this.request<AccountsGamertagListData, any>({
        path: `/v1/accounts/gamertag`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Initiate process for getting encrypted session for the user to interact with the embedded signer
     *
     * @tags Account
     * @name AccountsInitiateEncryptionSessionCreate
     * @request POST:/v1/accounts/initiate-encryption-session
     * @response `200` `AccountsInitiateEncryptionSessionCreateData` Default Response
     */
    accountsInitiateEncryptionSessionCreate: (params: RequestParams = {}) =>
      this.request<AccountsInitiateEncryptionSessionCreateData, any>({
        path: `/v1/accounts/initiate-encryption-session`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name AccountsEncryptionSessionCreate
     * @request POST:/v1/accounts/encryption-session
     * @response `201` `AccountsEncryptionSessionCreateData` Default Response
     */
    accountsEncryptionSessionCreate: (data: AccountsEncryptionSessionCreatePayload, params: RequestParams = {}) =>
      this.request<AccountsEncryptionSessionCreateData, any>({
        path: `/v1/accounts/encryption-session`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Generate a new session key to permit access to a users Portal wallet.
     *
     * @tags Account
     * @name AccountsSessionKeyCreate
     * @request POST:/v1/accounts/session-key
     * @response `201` `AccountsSessionKeyCreateData` Default Response
     */
    accountsSessionKeyCreate: (data: AccountsSessionKeyCreatePayload, params: RequestParams = {}) =>
      this.request<AccountsSessionKeyCreateData, any>({
        path: `/v1/accounts/session-key`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Fetch an active session key associated to the authenticated user
     *
     * @tags Account
     * @name AccountsSessionKeyList
     * @request GET:/v1/accounts/session-key
     * @response `200` `AccountsSessionKeyListData` Default Response
     */
    accountsSessionKeyList: (query: AccountsSessionKeyListParams, params: RequestParams = {}) =>
      this.request<AccountsSessionKeyListData, any>({
        path: `/v1/accounts/session-key`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name TransactionsCreate
     * @request POST:/v1/transactions
     * @response `201` `TransactionsCreateData` Default Response
     */
    transactionsCreate: (data: TransactionsCreatePayload, params: RequestParams = {}) =>
      this.request<TransactionsCreateData, any>({
        path: `/v1/transactions`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Fetch the transactions of the authenticated user
     *
     * @tags Transaction
     * @name TransactionsList
     * @request GET:/v1/transactions
     * @response `200` `TransactionsListData` Default Response
     */
    transactionsList: (query: TransactionsListParams, params: RequestParams = {}) =>
      this.request<TransactionsListData, any>({
        path: `/v1/transactions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name TransactionsIntentCreate
     * @request POST:/v1/transactions/intent
     * @response `201` `TransactionsIntentCreateData` Default Response
     */
    transactionsIntentCreate: (data: TransactionsIntentCreatePayload, params: RequestParams = {}) =>
      this.request<TransactionsIntentCreateData, any>({
        path: `/v1/transactions/intent`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name TransactionsConfirmCreate
     * @request POST:/v1/transactions/confirm
     * @response `201` `TransactionsConfirmCreateData` Default Response
     */
    transactionsConfirmCreate: (data: TransactionsConfirmCreatePayload, params: RequestParams = {}) =>
      this.request<TransactionsConfirmCreateData, any>({
        path: `/v1/transactions/confirm`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * @description Fetch the NFTS of the authenticated user
 *
 * @tags NFT
 * @name NftsList
 * @request GET:/v1/nfts
 * @response `200` `NftsListData` Default Response
 * @response `401` `{
    statusCode: 401,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `403` `{
    statusCode: 403,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 */
    nftsList: (query: NftsListParams, params: RequestParams = {}) =>
      this.request<NftsListData, NftsListError>({
        path: `/v1/nfts`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
 * @description Upload an image file to cloud storage
 *
 * @tags Images
 * @name ImagesCreate
 * @request POST:/v1/images/
 * @response `201` `ImagesCreateData` Default Response
 * @response `413` `{
    statusCode: 413,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `415` `{
    statusCode: 415,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 */
    imagesCreate: (data: ImagesCreatePayload, params: RequestParams = {}) =>
      this.request<ImagesCreateData, ImagesCreateError>({
        path: `/v1/images/`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
 * @description Returns the list of portal apps owned by the authenticated user
 *
 * @tags Apps
 * @name AppsList
 * @request GET:/v1/apps
 * @response `201` `AppsListData` Default Response
 * @response `401` `{
    statusCode: 401,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `403` `{
    statusCode: 403,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 */
    appsList: (query: AppsListParams, params: RequestParams = {}) =>
      this.request<AppsListData, AppsListError>({
        path: `/v1/apps`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Apps
 * @name AppsCreate
 * @request POST:/v1/apps
 * @response `201` `AppsCreateData` Default Response
 * @response `401` `{
    statusCode: 401,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `403` `{
    statusCode: 403,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 */
    appsCreate: (data: AppsCreatePayload, params: RequestParams = {}) =>
      this.request<AppsCreateData, AppsCreateError>({
        path: `/v1/apps`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * @description Fetch full details for an Application
 *
 * @tags Apps
 * @name AppsDetail
 * @request GET:/v1/apps/{appId}
 * @response `200` `AppsDetailData` Default Response
 * @response `401` `{
    statusCode: 401,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `403` `{
    statusCode: 403,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `404` `{
    statusCode: 404,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 */
    appsDetail: (appId: string, params: RequestParams = {}) =>
      this.request<AppsDetailData, AppsDetailError>({
        path: `/v1/apps/${appId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
 * @description Update the details of a Portal App
 *
 * @tags Apps
 * @name AppsPartialUpdate
 * @request PATCH:/v1/apps/{appId}
 * @response `200` `AppsPartialUpdateData` Default Response
 * @response `401` `{
    statusCode: 401,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `403` `{
    statusCode: 403,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `404` `{
    statusCode: 404,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 */
    appsPartialUpdate: (appId: string, data: AppsPartialUpdatePayload, params: RequestParams = {}) =>
      this.request<AppsPartialUpdateData, AppsPartialUpdateError>({
        path: `/v1/apps/${appId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * @description Delete an app
 *
 * @tags Apps
 * @name AppsDelete
 * @request DELETE:/v1/apps/{appId}
 * @response `204` `AppsDeleteData` Default Response
 * @response `401` `{
    statusCode: 401,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `403` `{
    statusCode: 403,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `404` `{
    statusCode: 404,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 */
    appsDelete: (appId: string, params: RequestParams = {}) =>
      this.request<AppsDeleteData, AppsDeleteError>({
        path: `/v1/apps/${appId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Apps
 * @name AppsCredentialsCreate
 * @request POST:/v1/apps/{appId}/credentials
 * @response `201` `AppsCredentialsCreateData` Default Response
 * @response `401` `{
    statusCode: 401,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `403` `{
    statusCode: 403,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 */
    appsCredentialsCreate: (appId: string, params: RequestParams = {}) =>
      this.request<AppsCredentialsCreateData, AppsCredentialsCreateError>({
        path: `/v1/apps/${appId}/credentials`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Game
 * @name GamesList
 * @request GET:/v1/games
 * @response `200` `GamesListData` Default Response
 * @response `401` `{
    statusCode: 401,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 * @response `403` `{
    statusCode: 403,
    error: string | null,
    details?: ({
    field: string,
    message: string,

})[] | null,

}` Default Response
 */
    gamesList: (query: GamesListParams, params: RequestParams = {}) =>
      this.request<GamesListData, GamesListError>({
        path: `/v1/games`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
