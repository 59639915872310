import { Api } from './generated';
import { config } from '@/config';

const api = new Api<string>({
  securityWorker: (token) => ({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }),
  baseUrl: config.API_ORIGIN,
  baseApiParams: {
    secure: true,
  },
});

export { api };
export * from './generated';
