import { OpenerError } from '@/util/error';

type Payload = {
  confirmed?: boolean;
  transactionHash?: string;
  data?: string;
};

export const postSuccess = (payload: Payload) => {
  if (window.opener) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    window.opener.postMessage(
      {
        type: 'success',
        payload,
      },
      '*',
    );
  } else {
    throw new OpenerError();
  }
};

export const postError = (message: string) => {
  if (window.opener) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    window.opener.postMessage(
      {
        type: 'error',
        payload: {
          message,
        },
      },
      '*',
    );
  } else {
    throw new OpenerError();
  }
};
