import { useRouteError, useNavigate } from 'react-router-dom';
import { SignerError } from '@/util/error';
import { Button } from '@/components/basic/button';
import { log } from '@/util/log';

const ErrorPage: React.FC = () => {
  const unknownError = useRouteError();
  const navigate = useNavigate();

  log.error(unknownError);

  let error: SignerError;

  if (!(unknownError instanceof SignerError)) {
    error = new SignerError([]);
  } else {
    error = unknownError;
  }

  const handleGoBack = () => {
    if (window.opener) {
      (window.opener as Window).postMessage({ type: 'error', payload: {} }, '*');
      window.close();
    } else {
      window.history.back();
    }
  };

  const handleRetry = () => {
    navigate(0);
  };

  return (
    <div className="flex max-w-[450px] flex-col items-center justify-center gap-5">
      <img src="/icons/portal-logo.svg" width="32px" height="36px" alt="Portal logo" className="text-black" />
      <h1 className="text-2xl">{error.title}</h1>
      <p className="text-center text-lg">{error.message}</p>
      {error.errors?.length > 0 && (
        <ul className="mb-6 list-inside list-disc">
          {error.errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )}
      <div className="flex w-full flex-col gap-3">
        {error.allowRefresh && (
          <Button variant={'secondary'} type="button" onClick={handleRetry}>
            Retry
          </Button>
        )}
        <Button type="button" onClick={handleGoBack}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
