import Openfort, { ShieldAuthType, ThirdPartyOAuthProvider, TokenType, EmbeddedState } from '@openfort/openfort-js';
import { config } from '@/config';
import { AccountsInitiateEncryptionSessionCreateData, api } from '@/api';
import { log } from '@/util/log';

export const openfort = new Openfort({
  baseConfiguration: {
    publishableKey: config.OPENFORT_PUBLISHABLE_KEY || '',
  },
  shieldConfiguration: {
    shieldPublishableKey: config.SHIELD_PUBLISHABLE_KEY || '',
    debug: config.SHIELD_DEBUG,
  },
});

export const createSessionKeyIntent = async (
  accessToken: string,
  chainId: number,
  policyId?: string,
  contractsWhitelisted?: string[],
  limit?: number,
) => {
  api.setSecurityData(accessToken);
  const response = await api.v1.accountsSessionKeyCreate({ chainId, policyId, contractsWhitelisted, limit });
  return response.data.data;
};

export const createSessionKey = async (
  accessToken: string,
  chainId: number,
  policyId?: string,
  contractsWhitelisted?: string[],
  limit?: number,
): Promise<void> => {
  const portalProxySessionKey = await createSessionKeyIntent(accessToken, chainId, policyId, contractsWhitelisted, limit);

  if (portalProxySessionKey.requiresNextAction && portalProxySessionKey.userOperationHash) {
    await openfort.sendSignatureTransactionIntentRequest(portalProxySessionKey.responseId, portalProxySessionKey.userOperationHash);
  }
};

export const getEmbeddedSignerUser = async ({
  idToken,
  externalProviderUserId,
}: {
  idToken: string;
  externalProviderUserId: string;
}): Promise<void> => {
  // Ensure we have the correct user signed in
  if (openfort.getEmbeddedState() > EmbeddedState.UNAUTHENTICATED) {
    try {
      const user = await openfort.getUser();
      if (user?.id !== externalProviderUserId) {
        log.info('Wrong session found - logging out');
        await openfort.logout();
      }
    } catch (e) {
      log.info('Failed to get user - logging out');
      log.info(e);
      await openfort.logout();
    }
  }

  try {
    await openfort.authenticateWithThirdPartyProvider({
      provider: ThirdPartyOAuthProvider.OIDC,
      tokenType: TokenType.ID_TOKEN,
      token: idToken,
    });
  } catch (e) {
    if (!(e instanceof Error) || !e.message.includes('409')) {
      throw e;
    }
    // Account already exists which is fine
  }
  const user = await openfort.getUser();
  if (user.id !== externalProviderUserId || openfort.getEmbeddedState() !== EmbeddedState.READY) {
    throw new Error('Embedded signer must be recovered');
  }
};

export const getAuthenticationMethod = async (accessToken: string) => {
  api.setSecurityData(accessToken);
  const response = await api.v1.accountsInitiateEncryptionSessionCreate();
  return {
    method: response.data.data.authenticationMethod,
    hint: response.data.data.userHint,
  };
};

export const getEncryptionSession = async (accessToken: string, code: string) => {
  api.setSecurityData(accessToken);
  const response = await api.v1.accountsEncryptionSessionCreate({
    code,
  });
  return response.data.data.encryptionSession;
};

export const configureEmbeddedSigner = async (
  idToken: string,
  accessToken: string,
  chainId: number,
  externalProviderUserId: string,
  authenticationMethod: AccountsInitiateEncryptionSessionCreateData['data']['authenticationMethod'],
  code: string,
): Promise<void> => {
  try {
    let recoveryPassword;
    let encryptionSession;
    if (authenticationMethod === 'EMAIL_OTP') {
      encryptionSession = await getEncryptionSession(accessToken, code);
    } else {
      recoveryPassword = code;
    }
    await openfort.configureEmbeddedSigner(
      chainId,
      {
        auth: ShieldAuthType.OPENFORT,
        token: idToken,
        authProvider: 'oidc',
        tokenType: 'idToken',
        encryptionSession,
      },
      recoveryPassword,
    );
  } catch (e) {
    if (!(e instanceof Error) || !e.message.includes('409')) {
      throw e;
    }
    // Account already exists which is fine
  }
  const user = await openfort.getUser();
  if (user.id !== externalProviderUserId || openfort.getEmbeddedState() !== EmbeddedState.READY) {
    throw new Error('Failed to recover embedded signer');
  }
};
