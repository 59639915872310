import { useState, useCallback } from 'react';

export class SignerError extends Error {
  title = 'An Error Occurred';
  override message = 'An error occurred while processing the request';
  errors: string[];
  allowRefresh = true;

  constructor(errors: string[] = []) {
    super();
    this.errors = errors;
  }
}

export class AuthenticationError extends SignerError {
  override title = 'Authentication error';
  override message = 'The password you entered was incorrect.';
}

export class RoutingError extends SignerError {
  override title = 'Page Not Found';
  override message = 'The page you requested could not be found.';
  override allowRefresh = false;
}

export class ConfigError extends SignerError {
  override title = 'Invalid Configuration';
  override message = 'There was an error configuring the embedded signer due to missing configuration';
}

export class OpenerError extends SignerError {
  override title = 'Window Error';
  override message = "The page that opened this popup can't be found. Please try again.";
}

/**
 * Throws an error from an async functionthat will be caught by the nearest error boundary
 * This allows us to make use of react router error elements even from callbacks and effects
 *
 * It is a little bit of a hack, but it works.
 *
 * @returns
 */
export const useErrorRoute = () => {
  const [, setError] = useState();
  return useCallback(
    (e: SignerError) => {
      setError(() => {
        throw e;
      });
    },
    [setError],
  );
};
