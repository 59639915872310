import { useEffect, useState } from 'react';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { useLocation } from 'react-router-dom';

import { createSessionKey } from '@/state/repo';
import { useErrorRoute, ConfigError, SignerError } from '@/util/error';
import { postSuccess } from '@/util/post-message';
import { log } from '@/util/log';
import { Spinner } from '@/components/basic/spinner';

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

type SignerProps = {
  accessToken: string;
  idToken: string;
  policyId: string;
  chainId: number;
  contractsWhitelisted: string[];
  limit: number;
  externalProviderUserId: string;
};

const validateQueryParams = (query: URLSearchParams): SignerProps => {
  const errors: string[] = [];
  const idToken = query.get('id_token');
  const accessToken = query.get('access_token');
  const policyId = query.get('policy_id');
  const chainIdStr = query.get('chain_id');
  const contractsWhitelisted = query.getAll('contracts_whitelisted');
  const limitStr = query.get('limit');
  const externalProviderUserId = query.get('external_provider_user_id');

  if (!accessToken) errors.push('Missing accessToken');
  if (!idToken) errors.push('Missing idToken');
  if (!chainIdStr) {
    errors.push('Missing chainId');
  } else if (isNaN(Number(chainIdStr))) {
    errors.push('Invalid chainId');
  }
  if (isNaN(Number(limitStr))) {
    errors.push('Invalid limit');
  }

  if (errors.length > 0) {
    throw new ConfigError(errors);
  }

  return {
    accessToken: accessToken as string,
    idToken: idToken as string,
    chainId: Number(chainIdStr),
    policyId: policyId as string,
    contractsWhitelisted,
    limit: Number(limitStr) | 1,
    externalProviderUserId: externalProviderUserId as string,
  };
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Session() {
  const query = useQuery();
  const [params, setParams] = useState<SignerProps | null>(null);
  const [generating, setGenerating] = useState(false);
  const throwError = useErrorRoute();
  if (!params) {
    setParams(validateQueryParams(query));
  }

  useEffect(() => {
    const doGenerate = async ({ accessToken, chainId, policyId, contractsWhitelisted, limit }: SignerProps) => {
      try {
        log.info('Generating session key.');
        await createSessionKey(accessToken, chainId, policyId, contractsWhitelisted, limit);
        postSuccess({
          confirmed: true,
        });
      } catch (e) {
        log.info('Failed to generate session key:', e);
        throwError(new SignerError());
        setGenerating(false);
      }
    };
    if (params && !generating) {
      setGenerating(true);
      void doGenerate(params);
    }
  }, [params, generating]);

  return (
    <>
      <img src="/icons/portal-logo.svg" width="32px" height="36px" alt="Portal logo" />
      {generating ? (
        <>
          <p className="mb-[26px] text-2xl">Generating session key</p>
          <Spinner />
        </>
      ) : null}
    </>
  );
}
